import React from 'react'

const ChevronThree = () => {
  return (
    <svg
      className="button__svg text-white stroke-current"
      width="15"
      height="29"
      viewBox="0 0 15 29"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g strokeWidth="2" fill="none">
        <path strokeOpacity="0.2" d="M14 1L7.59 7 1.225 1" />
        <path strokeOpacity="0.6" d="M14 11l-6.41 6-6.365-6" />
        <path d="M14 21l-6.41 6-6.365-6" />
      </g>
    </svg>
  )
}

export default ChevronThree
